<template>
  <div class="col-12 col-sm-6">
    <div class="login--col">
      <h2>{{ $t("reset.reset_new_password") }}</h2>
      <p>
        {{ $t("reset.reset_description") }}
      </p>
      <form
        action=""
        class="login--form login--form--fPw"
        @submit.prevent="checkForm"
      >
        <input
          type="text"
          :placeholder="$t('reset.reset_email')"
          class="login--form--input"
          v-model="email"
        />
        <input
          type="text"
          :placeholder="$t('reset.reset_password')"
          class="login--form--input"
          v-model="password"
        />
        <input
          type="text"
          :placeholder="$t('reset.reset_confirm_password')"
          class="login--form--input"
          v-model="password_confirmation"
        />
        <button type="submit" class="login--form--submit btn--blk">
          {{ $t("reset.reset_reset_button") }}
        </button>
      </form>
      <div class="login--form--input--error" v-if="errorMsg != ''">
        <ul>
          <li v-for="(error, index) in errorMsg" :key="index">
            {{ error }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  name: "resetPasswordForm",
  data() {
    return {
      lang: Cookies.get("HKGA-MAINSITE-LANGUAGE"),
      token: this.$route.query.code,
      email: "",
      password: "",
      password_confirmation: "",
      errorMsg: [],
      error: true
    };
  },
  methods: {
    async checkForm() {
      this.errorMsg = [];
      this.error = true;
      if (this.email == "") {
        this.errorMsg.push(this.$t("reset.reset_error_valid_email"));
        this.error = false;
      }

      if (this.password == "" || this.password_confirmation == "") {
        this.errorMsg.push(this.$t("reset.reset_error_enter_new_pw"));
        this.error = false;
      }

      if (
        this.password != this.password_confirmation ||
        this.password.length !== 8 ||
        this.password_confirmation.length !== 8
      ) {
        this.errorMsg.push(this.$t("reset.reset_password_not_match"));
        this.error = false;
      }

      if (this.error) {
        let formData = {
          token: this.$route.query.code,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation
        };

        this.errorMsg = [];
        await this.resetPassword(formData);
      }
    },
    async resetPassword(formData) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_BASE_API_URL}/resetPassword/`,
          {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData)
          }
        );

        if (!response.ok) {
          throw new Error("API Error");
        }

        const { data } = await response.json();

        this.$router.push("/login");
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login--form {
  margin-bottom: 20px;
  @include respond-to(screen-sm) {
    margin-bottom: 40px;
  }
  &--input {
    width: 100%;
    display: block;
    font-size: 18px;
    margin-bottom: 20px;
    border-bottom: 1px solid $color-black;
    padding: 5px 0 5px;
    &::placeholder {
      font-size: 18px;
      color: black;
    }
    @include respond-to(screen-sm) {
      font-size: 22px;
      margin-bottom: 30px;
      padding: 10px 0 5px;
      &::placeholder {
        font-size: 22px;
        color: black;
      }
    }
    &--error {
      color: red;
      margin: 0;
      padding: 0;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          font-size: 16px;
        }
      }
    }
  }
  &--submit {
    width: 100%;
    margin-top: 10px;
    @include respond-to(screen-sm) {
      margin-top: 0;
    }
  }
}

.login {
  &--col {
    margin-bottom: 50px;
    @include respond-to(screen-sm) {
      margin-bottom: 0px;
    }
    h2 {
      @include typo(bigTit);
      margin-bottom: 15px;
      @include respond-to(screen-sm) {
        margin-bottom: 20px;
      }
    }
    p {
      margin-bottom: 30px;
    }
  }
  .col-12:first-child .login--col {
    padding-right: 0px;
    @include respond-to(screen-sm) {
      padding-right: 50px;
    }
  }
  .col-12:last-child .login--col {
    padding-left: 0;
    @include respond-to(screen-sm) {
      padding-left: 50px;
    }
  }
}

a.crAc {
  @include transition(all 300ms ease-in-out);
  text-decoration: underline;
  font-size: 16px;
  &:hover {
    opacity: 0.5;
  }
  @include respond-to(screen-sm) {
    margin-bottom: 40px;
  }
}
</style>
